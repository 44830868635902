<template>
  <div>
    <el-dialog :title="text + '教材'"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               v-if="dialogVisible"
               ref="formRef"
               label-width="110px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="教材名称"
                          prop="textbook_name">
              <el-input placeholder="请输入"
                        v-model="form.textbook_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="科目"
                          prop="subject_id">
              <el-select style="width:100%"
                         v-model="form.subject_id"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in $parent.subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="城市"
                          prop="city_id">
              <div class="zhishidian">
                <el-cascader v-model="form.city_id"
                             :options="options"
                             :props="props"
                             style="width:100%"
                             @change="handleChange"></el-cascader>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="教材描述">
              <el-input placeholder="请输入"
                        type="textarea"
                        :rows="4"
                        v-model="form.textbook_desc"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCityTree } from '@/api/city.js'

export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      rules: {
        textbook_name: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        subject_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      text: '',
      props: { multiple: true, value: 'city_id', label: 'name', children: 'child' },
      options: [],
    }
  },

  methods: {
    handleClose () {
      this.dialogVisible = false
      this.$refs.formRef.resetFields()
    },
    getForm (form) {
      var cityArr = []
      if (form.city_id && form.city_id.length) {
        form.city_id.forEach(item => {
          var obj = {}
          obj.id = item[1]
          obj.ids = item.join(',')
          cityArr.push(obj)
        });
        form.city_id = cityArr
      }
      return form
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form = this.getForm(form)

        this.$http({
          url: '/api/v2/textbook/add',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '操作成功!',
            type: 'success'
          });
          this.handleClose()
          if (this.text == "新增") {
            this.$parent.search()
          } else {
            this.$parent.refresh()

          }
        })
      })
    },
    async getCityTree () {
      const { data } = await getCityTree()
      this.options = data
    },
    handleChange (e) {
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
.zhishidian {
  height: 33px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  border-radius: 4px;
  ::v-deep .el-cascader__tags .el-tag:not(.is-hit) {
    height: 33px;
  }
  ::v-deep .el-cascader__tags .el-tag {
    background-color: #ffffff;
  }
  ::v-deep .el-cascader__tags {
    height: 33px;
  }
  ::v-deep .el-icon-close {
    display: none;
  }
}
</style>