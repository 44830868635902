<template>
  <div>
    <el-form label-width="80px"
             :model="form">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="关键字:">
            <el-input v-model="form.keyword"
                      placeholder="请输入"
                      style="width:100%" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="科目:">
            <el-select style="width:100%"
                       v-model="form.subject_id"
                       clearable
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-button type="primary"
                     @click="search">查询</el-button>
          <el-button type="primary"
                     @click="add">新增</el-button>
          <!-- /classes/textbook -->
          <el-button type="primary"
                     @click="$router.push('/classes/textbook')">
            教材管理
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />

    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/form.vue'
export default {
  data () {
    return {
      tableColumns: [
        { prop: 'textbook_name', align: 'center', label: '教材名称', width: '150', showToopic: false },
        { prop: 'subject_name', align: 'center', label: '科目', width: '150', showToopic: false },
        { prop: 'city_names', align: 'center', label: '城市', width: '', showToopic: false },
        { prop: 'textbook_desc', align: 'center', label: '描述', width: '150', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '180', type: 'img', btns: [
            { content: '修改教材', type: 'edit', event: 'editRow' },
          ]
        },
      ],
      // 暂不删除  form表单用的这里的科目列表
      subjectList: [],
      tableData: [],
      page: 1,
      size: 10,
      totalElements: 0,
      form: {
        subject_id: '',
        keyword: ''
      },
      pageName: 'slcz_textbook_admin',
    }
  },
  components: {
    Form
  },
  mounted () {
    this.getSubjectList()
    this.setTablesColums()
  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    getSubjectList () {
      this.$http({
        url: '/api/v2/chapter/subject',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.list
      })
    },
    initData (page, limit) {
      let params = {
        subject_id: this.form.subject_id,
        keyword: this.form.keyword,
        page,
        limit
      }
      this.$http({
        url: '/api/v2/textbook/lst',
        method: 'get',
        params
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    editRow (row) {

      this.getPath(row.textbook_id, row)

    },
    add () {
      let form = {
        textbook_name: '',
        subject_id: '',
        textbook_desc: ''
      }
      this.$refs.FormRef.text = '新增'
      this.$refs.FormRef.form = form
      this.$refs.FormRef.getCityTree()
      this.$refs.FormRef.dialogVisible = true
    },
    getPath (id, row) {
      this.$http({
        url: '/api/v2/textbook/get_paths',
        method: 'get',
        params: {
          id: id
        }
      }).then(res => {
        let form = JSON.parse(JSON.stringify(row))
        form.city_id = res.data.map(item => item.split(','))
        this.$refs.FormRef.text = '修改'
        this.$refs.FormRef.form = form
        this.$refs.FormRef.getCityTree()
        this.$refs.FormRef.dialogVisible = true
      })
    }
  }
}
</script>

<style lang="scss">
</style>